import {previewUpload} from './constants/signalmanConstants';

// We'll allow setting custom design previews only for select categories - YDH/YLH enabled ones. This is to prevent
// displaying upload bar in categories where the user wouldn't be able to preview the custom design on PLP.
export default function allowSettingCustomDesignPreview(
  iframer,
  designPreviewEnabledCategoryIds,
  categoryId
) {
  return (
    previewUpload === 'test' &&
    !iframer &&
    designPreviewEnabledCategoryIds.includes(parseInt(categoryId))
  );
}
