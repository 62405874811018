import Rollbar from 'rollbar';
import {ignoredMessages} from './ignore';

const stagingOrProduction = ['production', 'staging'].includes(__ENV__);

const rollbar = new Rollbar({
  accessToken: process.env.ROLLBAR_JS_ACCESS_TOKEN,
  captureUncaught: stagingOrProduction,
  captureUnhandledRejections: stagingOrProduction,
  enabled: true,
  hostSafeList: ['customink.com'],
  ignoredMessages,
  payload: {
    environment: __ENV__,
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true
      }
    }
  }
});

export default rollbar;
