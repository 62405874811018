import axios from 'axios';

//TODO rely on process.env once shakapacker env matches Rails env
const uploadServiceBase = () =>
  window.__ENV__ === 'production'
    ? 'https://uploads-lbr.out.customink.com'
    : 'https://uploads-lbr.lambda-staging.customink.com';
const baseUrl = uploadServiceBase() || process.env.UPLOAD_SERVICE_URL;
const apiVersion = 'v1';

const uploadServiceInstance = axios.create({
  baseURL: baseUrl
});

export function uploadFile(file) {
  const formData = new FormData();
  formData.append('upload', file, file.name);

  return uploadServiceInstance
    .post(`/uploads/${apiVersion}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData,
      transformRequest: [(data) => data]
    })
    .then((response) => response.data);
}

export function getUploadUrl(uploadUrl) {
  return `${baseUrl}${uploadUrl}`;
}

export function getUploadManipulateUrl(uploadId, params) {
  return `${baseUrl}/uploads/manipulate/${uploadId}${params}`;
}
