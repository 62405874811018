import tinycolor from 'tinycolor2';

const isValidColorComponent = (color) => {
  return (
    color !== null && Number.isInteger(color) && color >= 0 && color <= 255
  );
};

export function isValidRGB({b, g, r}) {
  return (
    isValidColorComponent(r) &&
    isValidColorComponent(g) &&
    isValidColorComponent(b)
  );
}

export function rgbToHex(rgb) {
  if (isValidRGB(rgb)) {
    return tinycolor(rgb).toHexString();
  }
  return null;
}
