import {useEffect, useState} from 'react';
import {debounce} from 'throttle-debounce';

const getDeviceConfig = (width) => {
  if (width < 700) {
    return 'sm';
  }
  if (width >= 700 && width < 1024) {
    return 'md';
  }
  if (width >= 1024 && width < 1300) {
    return 'lg';
  }
  return 'xl';
};

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() =>
    getDeviceConfig(window.innerWidth)
  );

  useEffect(() => {
    const calcInnerWidth = debounce(200, () => {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    });

    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return brkPnt;
};

export default useBreakpoint;
