const baseLabUrl = `${window.location.origin}/lab`;

export const getBaseLabUrlObject = () => {
  return new URL(baseLabUrl);
};

const labLink = (
  colorId,
  preferSingles,
  cid,
  legacyStyleId,
  previewUploadEnabled = false
) => {
  let pk = colorId;
  const link = getBaseLabUrlObject();
  const customDesignPreview = previewUploadEnabled
    ? ProductCatalog.LocalStorage.read('custom_design_preview')
    : null;
  if (colorId === parseInt(legacyStyleId, 10)) {
    pk = legacyStyleId;
  }

  link.searchParams.append('PK', pk);
  link.searchParams.append('prefer_singles', preferSingles);
  if (cid) link.searchParams.append('cid', cid);
  if (customDesignPreview) {
    link.searchParams.delete('cid');
    // Unfortunately we can't use searchParams here as it'll URL encode the param and it usually contains '%' which then
    // gets encoded into '%25' which then isn't properly handled by either MMS Images or lab.
    const uploadParameter = link.searchParams.size > 0 ? '&U=' : '?U=';
    link.search = `${link.search}${uploadParameter}${customDesignPreview.data.url.replace('/uploads/', '')}`;
  }

  return link;
};

export default labLink;
