class Pricing {
  constructor() {
    this.ipDataGet();
    this.defaultAlgorithm = 'default-2022-03-24';
    this.defaultQuoteEl = this.defaultQuoteEl.bind(this);
    this.defaultQuoteCategory = this.defaultQuoteCategory.bind(this);
    this.isDefaultQuoteCategory = this.isDefaultQuoteCategory.bind(this);
    this.fetchOrdQtyPricing = this.fetchOrdQtyPricing.bind(this);
    $(document).on('updated.quotecontrol.colors', function () {
      ProductCatalog.Pricing.fetchOrdQtyPricing();
    });
    $(document).on('updated.quotecontrol.qty', function () {
      ProductCatalog.Pricing.fetchOrdQtyPricing();
    });
  }

  zipcode() {
    return this.ipResponse != null ? this.ipResponse.postal_code : undefined;
  }

  defaultQuoteEl() {
    return $('.pc-Styles-defaultQuote-tooltip');
  }

  defaultQuoteCategory() {
    const id = this.defaultQuoteEl().attr('data-category-id');
    if (id == null) {
      return null;
    }
    return parseInt(id);
  }

  isDefaultQuoteCategory() {
    return $('.pc-Styles-products.is-defaultQuoteShown').length > 0;
  }

  // Private

  ipDataGet() {
    return StyleBitz.Location.info((data) => {
      return (this.ipResponse = data);
    });
  }

  setProductPriceStatus(productId, status = 'fetch') {
    const $productCard = $('.pc-ProductCard[data-style-id=' + productId + ']');
    const $priceArea = $($productCard.find('.pc-ProductCard-detailPrice'));
    $priceArea.removeClass('is-highlighted is-belowMinimum');
    if (status === 'fetch') {
      $productCard.css({display: 'block'});
      $priceArea.html('calculating price...');
      $priceArea.css({display: 'block'});
    } else if (status === 'prompt') {
      $productCard.css({display: 'block'});
      $priceArea.html(
        'Increase <span class="is-extra">estimated</span> qty for pricing'
      );
      $priceArea.addClass('is-belowMinimum');
      $priceArea.css({display: 'block', visibility: 'visible'});
    } else if (status === 'error') {
      $priceArea.html('failed to quote');
    }
  }

  updateProductPrice(productId, quantity) {
    return (data) => {
      const $productCard = $(
        '.pc-ProductCard[data-style-id=' + productId + ']'
      );
      const $priceArea = $($productCard.find('.pc-ProductCard-detailPrice'));
      const extra_text =
        (quantity > 1 ? ' items' : ' item') +
        '<span class="pc-ProductCard-detailPriceInfo" ' +
        'data-tooltip="#default_quote_tooltip"></span>';
      $priceArea.removeClass('is-highlighted is-belowMinimum');
      $priceArea.html(
        '$' + data.original.each + ' each for ' + quantity + extra_text
      );

      $priceArea.css({
        display: 'block'
      });
    };
  }

  fetchOrdQtyPricing(ordQty, quoteFc, quoteBc, formsSelector) {
    ordQty = ordQty || parseInt($('.pc-QuoteControl-form-input').val());
    quoteFc =
      quoteFc || parseInt($('.pc-QuoteControl-form-input#quote_fc').val()) || 1;
    quoteBc =
      quoteBc || parseInt($('.pc-QuoteControl-form-input#quote_bc').val()) || 0;
    formsSelector = formsSelector || '.pc-ProductCard-quoteForm';

    const path = '/products/quotes/new';

    $(formsSelector).each((i, quoteForm) => {
      const $quoteForm = $(quoteForm);
      const $productCard = $quoteForm.closest('.pc-ProductCard');
      const $priceArea = $($productCard.find('.pc-ProductCard-detailPrice'));
      const $colorSwatch = $($productCard.find('.pc-Swatch.is-selected'));
      const productId = $quoteForm.data('productId');
      const swatchId = $colorSwatch.data('id');

      if ($.trim($priceArea.text()).length > 0) {
        $priceArea.css({
          display: 'block',
          visibility: 'visible'
        });
        return true;
      }

      $quoteForm.find('.pc-ProductCard-quoteForm--quantity').val(ordQty);
      $quoteForm
        .find('.pc-ProductCard-quoteForm--colors-front')
        .val(quoteFc > 0 ? quoteFc : 1);
      $quoteForm.find('.pc-ProductCard-quoteForm--colors-back').val(quoteBc);

      if (ordQty < (swatchId ? $colorSwatch : $productCard).data('min-qty')) {
        return this.setProductPriceStatus(productId, 'prompt');
      }

      const colorId =
        swatchId ||
        (ordQty >= $productCard.data('bulk-qty')
          ? $productCard.data('defaultQuoteColorId')
          : $productCard.data('singlesQuoteColorId'));

      if (colorId !== undefined) {
        $quoteForm.find('.pc-ProductCard-quoteForm--productId').val(colorId);
      }

      const quoteParams = $quoteForm.serialize();

      this.setProductPriceStatus(productId);
      return $.ajax(path, {
        type: 'GET',
        dataType: 'json',
        data: quoteParams,
        error: (jqXHR, textStatus, errorThrown) => {
          this.setProductPriceStatus(productId, 'error');
        },
        success: this.updateProductPrice(productId, ordQty)
      });
    });

    this.updatePriceToolTip(ordQty, quoteFc, quoteBc);
  }

  updatePriceToolTip(ordQty, quoteFc, quoteBc) {
    const text = ordQty > 1 ? ordQty + ' items:' : '1 item:';
    $('.pc-Styles-defaultQuote-tooltip-header').html(
      'All inclusive price for ' + text
    );
    const colorCount = quoteFc + quoteBc;
    const colorCountText =
      colorCount > 1 ? `${colorCount} ink colors` : `${colorCount} ink color`;
    const designSideCount = quoteFc > 0 && quoteBc > 0 ? 2 : 1;
    $('.pc-Styles-defaultQuote-tooltip-colorInfo').html(
      `${colorCountText}, ${designSideCount}-sided design`
    );
  }
}

ProductCatalog.Pricing = new Pricing();

export default Pricing;
